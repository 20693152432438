<template>
    <section class="invoice-add-wrapper">
        <b-row class="invoice-add">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="12" md="12">
                <!-- Col: Left (Invoice Container) -->
                <b-col cols="12" xl="12" md="8">
                    <validation-observer ref="invoiceRules">
                        <b-form @submit.prevent>
                            <b-card no-body class="invoice-preview-card">
                                <!-- Header -->
                                <b-card-body class="bg-primary invoice-padding pb-0">
                                    <div class=" d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                                        <!-- Header: Left Content -->
                                        <div>
                                            <div class="logo-wrapper">
                                                <quote-logo :invoiceLogo="settingURL + 'invoice_logo.png'" />
                                            </div>

                                            <p class="card-text mb-25 text-white">{{ $func.getName() }}, {{ $func.getStreet() }} <br /></p>
                                            <p class="card-text mb-25 text-white">{{ $func.getTown() }} {{ $func.getPostCode() }}, {{ $func.getCountry() }}</p>
                                            <p class="card-text mb-0 text-white">
                                                {{ $func.getPhone() }}
                                            </p>
                                        </div>

                                        <b-col cols="12" md="6">
                                            <!-- Header: Right Content -->
                                            <div class="invoice-number-date mt-md-0 mt-2 float-right">
                                                <span class="title text-white">
                                                    Invoice No
                                                </span>
                                                <div>
                                                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                                                        <b-form-input style="width:100%; " id="invoice-data-id" v-model="invoiceData.id" disabled />
                                                    </b-input-group>
                                                </div>
                                                <span class="title text-white">
                                                    Date
                                                </span>
                                                <div>
                                                    <validation-provider name="Date" #default="{ errors }" rules="required">
                                                        <flat-pickr
                                                            title="Date"
                                                            style="width:100%; background-color:white"
                                                            v-model="invoiceData.date"
                                                            :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }"
                                                            class="form-control invoice-edit-input"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </div>

                                                <span class="title text-white">
                                                    Due Date:
                                                </span>
                                                <div>
                                                    <validation-provider name="Due Date" #default="{ errors }" rules="required">
                                                        <flat-pickr
                                                            style="width:100%;  background-color:white"
                                                            v-model="invoiceData.dueDate"
                                                            :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }"
                                                            class="form-control invoice-edit-input"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                        </b-col>
                                    </div>
                                </b-card-body>

                                <!-- Spacer -->
                                <hr class="invoice-spacing" />

                                <!-- Invoice Client & Payment Details -->

                                <b-card-body class="invoice-padding pt-0">
                                    <b-row class="invoice-spacing">
                                        <!-- Col: Invoice To -->

                                        <b-col cols="12" xl="6" class="mb-lg-1">
                                            <h6 class="mb-2">
                                                Customer
                                            </h6>

                                            <!-- Select Client -->
                                            <validation-provider name="First Name" #default="{ errors }" rules="required">
                                                <v-select
                                                    v-model="invoiceData.customerId"
                                                    :options="dropCustomers"
                                                    :reduce="(val) => val.id"
                                                    label="value"
                                                    input-id="customer-data"
                                                    :clearable="false"
                                                    @input="clientSelected"
                                                >
                                                </v-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>

                                            <!-- Selected Client -->
                                            <div v-if="invoiceData.client" class="mt-1">
                                                <h6 class="mb-25">
                                                    {{ invoiceData.client.name }}
                                                </h6>
                                                <b-card-text class="mb-25">
                                                    {{ invoiceData.client.company }}
                                                </b-card-text>
                                                <b-card-text class="mb-25"> {{ invoiceData.client.address }} {{ invoiceData.client.country }} </b-card-text>
                                                <b-card-text class="mb-25">
                                                    {{ invoiceData.client.contact }}
                                                </b-card-text>
                                                <b-card-text class="mb-0">
                                                    {{ invoiceData.client.companyEmail }}
                                                </b-card-text>
                                            </div>
                                        </b-col>

                                        <b-col cols="12" xl="6" class="mb-lg-1">
                                            <h6 class="mb-2">
                                                Invoice Currency
                                            </h6>

                                            <v-select v-model="invoiceData.currency" :options="currencies" :reduce="(val) => val.id" label="code" :clearable="false" input-id="role" />
                                        </b-col>
                                    </b-row>
                                </b-card-body>

                                <!-- Items Section -->
                                <b-card-body class="invoice-padding form-item-section">
                                    <!-- div ref="form" class="repeater-form" :style="{ height: trHeight }" -->
                                    <div ref="form" class="repeater-form">
                                        <b-row v-for="(item, index) in invoiceData.items" :key="index" ref="row" class="pb-2">
                                            <!-- Item Form -->
                                            <!-- ? This will be in loop => So consider below markup for single item -->
                                            <b-col cols="12">
                                                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                                <div class="d-none d-lg-flex">
                                                    <b-row class="flex-grow-1 px-1">
                                                        <!-- Single Item Form Headers -->
                                                        <b-col cols="12" lg="5">
                                                            Line Item
                                                        </b-col>
                                                    </b-row>
                                                    <div class="form-item-action-col" />
                                                </div>

                                                <!-- Form Input Fields OR content inside bordered area  -->
                                                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                                                <div class="d-flex border rounded">
                                                    <b-row class="flex-grow-1 p-2">
                                                        <!-- Single Item Form Headers -->

                                                        <b-col cols="12" lg="1" class="text-center ">
                                                            <span>
                                                                Event Invoiced
                                                            </span>
                                                            <b-form-checkbox inline class="mt-1" v-model="item.main"> </b-form-checkbox>
                                                        </b-col>

                                                        <b-col cols="12" lg="2">
                                                            <span>
                                                                PO Number
                                                            </span>
                                                            <v-select v-model="item.poNumber" :value="item.itemCategory" :options="poNumbers" label="poNumber" :clearable="true"> </v-select>
                                                        </b-col>

                                                        <b-col cols="12" lg="1">
                                                            <span>
                                                                Date
                                                            </span>
                                                            <flat-pickr title="Date" v-model="item.date" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" class="form-control" />
                                                        </b-col>

                                                        <b-col cols="12" lg="2">
                                                            <span>
                                                                Item
                                                            </span>
                                                            <validation-provider name="Item" #default="{ errors }" rules="required">
                                                                <b-form-input v-model="item.itemTitle" type="text" />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col cols="12" lg="1">
                                                            <span>
                                                                VAT (%)
                                                            </span>
                                                            <cleave id="options" v-model="item.vat" class="form-control" :raw="true" :options="options.percent" />
                                                        </b-col>

                                                        <b-col cols="12" lg="1">
                                                            <span>
                                                                Cost
                                                            </span>
                                                            <validation-provider name="Cost" #default="{ errors }" rules="required">
                                                                <!-- <b-form-input v-model="item.cost" type="number" /> -->
                                                                <cleave id="cost" v-model="item.cost" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col cols="12" lg="1">
                                                            <span>
                                                                Quantity
                                                            </span>
                                                            <validation-provider name="Quantity" #default="{ errors }" rules="required">
                                                                <!-- <b-form-input v-model="item.qty" type="number" /> -->
                                                                <cleave id="quantity" v-model="item.qty" class="form-control" :raw="true" :options="$func.getInputOptions().number" />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col cols="12" lg="2">
                                                            <span>
                                                                Description
                                                            </span>
                                                            <b-form-input v-model="item.description" type="text" class=" mb-lg-0" />
                                                        </b-col>

                                                        <b-col cols="12" lg="1">
                                                            <span>
                                                                <strong>Price </strong>
                                                            </span>
                                                            <br />
                                                            <span>
                                                                <strong>{{ $func.formatPrice(item.cost * item.qty + (item.cost * item.qty * item.vat) / 100, invoiceData.currency) }}</strong>
                                                            </span>

                                                            <br />
                                                        </b-col>
                                                    </b-row>
                                                    <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                                                        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewItemInItemForm">
                                        Add Item
                                    </b-button>
                                </b-card-body>

                                <!-- Invoice Description: Total -->
                                <b-card-body class="invoice-padding pb-0">
                                    <b-row>
                                        <!-- Col: Sales Persion -->

                                        <!-- Col: Total -->
                                        <b-col cols="12" md="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                                            <div class="invoice-total-wrapper">
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title">
                                                        Subtotal:
                                                    </p>
                                                    <p class="invoice-total-amount">{{ $func.formatPrice(subTotal, invoiceData.currency) }}</p>
                                                </div>

                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title">
                                                        Tax:
                                                    </p>
                                                    <p class="invoice-total-amount">{{ $func.formatPrice(totalTax, invoiceData.currency) }}</p>
                                                </div>
                                                <hr class="my-50" />
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title">
                                                        Total:
                                                    </p>
                                                    <p class="invoice-total-amount">{{ $func.formatPrice(total, invoiceData.currency) }}</p>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-card-body>

                                <!-- Spacer -->
                                <hr class="invoice-spacing" />

                                <!-- Note -->
                                <b-card-body class="invoice-padding pt-0">
                                    <span class="font-weight-bold">Note: </span>
                                    <b-form-textarea v-model="invoiceData.note" />
                                </b-card-body>
                                <b-card-body class="invoice-padding pt-0">
                                    <b-col cols="12">
                                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="saveInvoice" variant="primary" class="mr-1 float-right">
                                            Save
                                        </b-button>
                                    </b-col>
                                </b-card-body>
                            </b-card>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>

            <!-- Right Col: Card -->
        </b-row>
        <invoice-sidebar-add-new-customer />
    </section>
</template>

<script>
import QuoteLogo from "@core/layouts/components/QuoteLogo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "../invoiceStoreModule";
import InvoiceSidebarAddNewCustomer from "../InvoiceSidebarAddNewCustomer.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Cleave from "vue-cleave-component";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BFormCheckbox,
        BPopover,
        flatPickr,
        vSelect,
        QuoteLogo,
        InvoiceSidebarAddNewCustomer,
        ValidationProvider,
        ValidationObserver,
        Cleave,
    },
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    mixins: [heightTransition],

    setup() {
        const INVOICE_STORE_MODULE_NAME = "invoice";

        // Register module
        if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME);
        });

        const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];

        return {
            paymentMethods,
        };
    },

    data() {
        return {
            changed: false,
            settingURL: store.state.app.settingURL,
            filter: null,
            filterOn: [],
            poNumbers: [],
            currencies: [],

            perPage: localStorage.getItem("customerShow") ?? 10,
            itemFormBlankItem: {
                cost: null,
                qty: null,
                description: null,
                inventoryId: null,
                vat: 20,
                main: false,
            },
            invoiceData: {
                items: [
                    {
                        poNumber: null,
                        date: null,
                        itemTitle: null,
                        cost: null,
                        qty: null,
                        description: null,
                        vat: 20,
                        main: false,
                    },
                ],
                event_id: null,
                customerId: null,
                id: null,
                date: null,
                dueDate: null,
                note: null,
                status: null,
                currency: 1,
            },
            required,
            email,
            dropCustomers: [],

            currentPage: 1,
            perPageOptions: [10, 25, 50, 100],
            colWidthFactor: null,
            totalRows: 0,
            baseURL: store.state.app.baseURL,
            formShow: false,

            statusType: null,
            status: null,

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    numeralPositiveOnly: true,
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },
            required,
            email,
        };
    },

    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    computed: {
        subTotal() {
            var result = 0;
            this.invoiceData.items.forEach((element) => {
                result = result + element.cost * element.qty;
            });
            return result;
        },

        totalTax() {
            var result = 0;

            var vat = 0;
            this.invoiceData.items.forEach((element) => {
                if (element.vat != null && element.vat != "" && element.vat != 0) {
                    vat = (element.cost * element.qty * parseFloat(element.vat)) / 100;
                }

                result = result + vat;

                vat = 0;
            });
            return result ? result : 0;
        },

        total() {
            var result = this.subTotal + this.totalTax;

            return result ? result : 0;
        },
    },

    watch: {
        "invoiceData.items": {
            handler: function(val, before) {
                if (val != null || val != "None") {
                    if (this.invoiceData.id != null && before[0].itemTitle != null && before[0].itemCategory != null && before[0].cost != null) {
                        this.invoiceData.changed = true;
                    }
                }
            },
        },

        "invoiceData.cost_list": {
            handler: function(val, before) {},
            deep: true,
        },
    },

    methods: {
        addNewItemInItemForm() {
            this.$refs.form.style.overflow = "hidden";
            this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));

            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.form.style.overflow = null;
                }, 350);
            });
        },
        removeItem(index) {
            this.invoiceData.items.splice(index, 1);
            //this.trTrimHeight(this.$refs.row[0].offsetHeight);
            this.invoiceData.changed = true;
        },

        saveInvoice() {
            this.formShow = true;
            if (this.invoiceData.items.length > 0) {
                this.$refs.invoiceRules.validate().then((success) => {
                    if (success) {
                        if (router.currentRoute.params.id) {
                            store
                                .dispatch("invoice/updateInvoice", this.invoiceData)
                                .then((response) => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Entry has been saved successfully",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    router.push({ name: "invoice-preview", params: { id: response.data.id } });
                                })
                                .catch((e) => {
                                    this.$swal({
                                        title: "Error",
                                        text: "Process Failed. Please try again or contact support.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                });
                        } else {
                            store
                                .dispatch("invoice/saveInvoice", this.invoiceData)
                                .then((response) => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Entry has been saved successfully",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    this.formShow = false;

                                    router.push({ name: "invoice-preview", params: { id: response.data.id } });
                                })
                                .catch((e) => {
                                    this.formShow = false;

                                    this.$swal({
                                        title: "Error",
                                        text: "Process Failed. Please try again or contact support.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                });
                        }
                    } else {
                        this.formShow = false;
                    }
                });
            } else {
                this.$swal({
                    title: "Error",
                    text: "Process Failed. Please try again or contact support.",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });

                this.formShow = false;
            }
        },

        getByIdIncome() {
            if (router.currentRoute.params.id) {
                store
                    .dispatch("invoice/fetchInvoice", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response.status == 200) {
                            const d = new Date(response.data.date);
                            const f = new Date(response.data.dueDate);

                            response.data.date = d;
                            response.data.dueDate = f;
                            this.invoiceData = response.data;
                            // this.clientSelected();
                            this.formShow = false;
                        } else {
                            router.push({ name: "error-404" });
                        }
                    })
                    .catch((error) => {
                        this.formShow = false;

                        this.$swal({
                            title: "Error",
                            text: "Process Failed. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });

                        router.push({ name: "error-404" });
                    });
            } else {
                this.formShow = false;
            }
        },

        customerDrop() {
            store
                .dispatch("invoice/customerDropInvoice")
                .then((response) => {
                    this.dropCustomers = response.data.clients;
                    if (this.invoiceData.customerId != null) {
                        this.poNumbers = this.dropCustomers.find((x) => x.id == this.invoiceData.customerId).poNumbers;
                    }

                    this.currencies = response.data.currencies;
                    this.invoiceData.currency = this.$func.getDefaultCurrency();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        clientSelected() {
            this.poNumbers = this.dropCustomers.find((x) => x.id == this.invoiceData.customerId).poNumbers;
        },
    },

    created() {
        this.customerDrop();
        this.getByIdIncome();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
    .add-new-client-header {
        padding: $options-padding-y $options-padding-x;
        color: $success;

        &:hover {
            background-color: rgba($success, 0.12);
        }
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
    background-color: $product-details-bg;
}

.form-item-action-col {
    width: 27px;
}

.repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;

        .dark-layout & {
            background-color: unset;
        }
    }
}

.dark-layout {
    .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
            background-color: $theme-dark-card-bg;
        }
    }
}
</style>
